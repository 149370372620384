import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.css'; // Custom CSS for additional styling

const Main = () => {
  let [text] = useTypewriter({
    words: ['Artificial Intelligence','Industrial IoT(IIoT)', 'Blockchain'],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });

  return (
    <div className="main-container d-flex align-items-center justify-content-center flex-column vh-100">
      <div className="text-center">
        <div></div>
        <h1 className="fw-bold mb-4 display-4 main-heading">Revolutionizing Industrial Maintenance with Predictive Insights</h1>
        <div className="d-flex align-items-center text-container flex-column">
          <h1 className="text-warning me-2 fw-bold static-text">Empowering Industries with</h1>
          <div className="d-flex align-items-center cursor-text-container">
            <h1 className="fs-1 fw-bold me-2 text-light dynamic-text">{text}</h1>
            <h1 className="text-warning fs-1 me-2 text-light dynamic-text"><Cursor cursorStyle="|" /></h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
