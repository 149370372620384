import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import './Features.css';

const features = [
    {
        name: 'IoT Integration',
        imageSrc: './IOT.webp',
        description: "The Internet of Things (IoT) has revolutionized the way we interact with technology, and its impact on industrial maintenance is profound. At Klvin.ai, we deploy state-of-the-art IoT sensors on your machinery to continuously gather data on vital parameters. These sensors act as the eyes and ears of your maintenance system, providing a constant stream of information about your equipment's health. This seamless integration ensures that you have real-time visibility into your operations, allowing for immediate intervention when anomalies are detected."
    },
    {
        name: 'AI-Driven Analytics',
        imageSrc: './AIDriven.webp',
        description: "Data by itself is powerful, but its true potential is unlocked when analyzed intelligently. Our AI-driven analytics engine processes the vast amounts of data collected by our IoT sensors. By leveraging machine learning algorithms, we can identify patterns and trends that are invisible to the human eye. These insights enable you to make informed decisions about maintenance schedules, resource allocation, and operational improvements. Our AI models are continuously learning and evolving, ensuring that your maintenance strategy is always one step ahead."
    },
    {
        name: 'Blockchain Security',
        imageSrc: './BlockChain.webp',
        description: "In today's digital age, data security is more critical than ever. With Klvin.ai, you can rest assured that your data is safe and tamper-proof. We utilize blockchain technology to create an immutable ledger of all maintenance activities and equipment performance data. Each transaction is securely logged and cannot be altered, providing a transparent and trustworthy record. This level of security not only protects your data but also enhances accountability and compliance with industry standards."
    },
    {
        name: 'Real-Time Monitoring',
        imageSrc: './RTM.webp',
        description: "The ability to monitor your equipment in real-time is a game-changer for industrial maintenance. Our system provides a live view of your machinery's performance, accessible from anywhere at any time. This real-time monitoring allows for immediate detection of issues and swift intervention, preventing minor problems from escalating into major failures. With Klvin.ai, you have the power to keep a constant watch on your operations, ensuring smooth and uninterrupted productivity."
    },
    {
        name: 'Custom Dashboards',
        imageSrc: './customDash.webp',
        description: "Every industry is unique, and so are its maintenance needs. That's why we offer customizable dashboards that allow you to tailor the information you see to your specific requirements. Whether you need a high-level overview of your entire operation or detailed insights into a particular piece of equipment, our dashboards can be configured to meet your needs. With easy-to-use interfaces and intuitive design, our dashboards provide the clarity and control you need to optimize your maintenance strategy."
    }
];

const Features = () => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    return (
        <Container className="py-5">
            <h1 className="text-center mb-5 fw-bold">Features</h1>
            <Row>
                <Col lg={3} className="feature-list">
                    {features.map((feature, index) => (
                        <Button
                            key={index}
                            variant="link"
                            className="feature-button text-start w-100"
                            onClick={() => setSelectedFeature(feature)}
                        >
                            {feature.name}
                        </Button>
                    ))}
                </Col>
                <Col lg={9}>
                    <Card className="feature-content">
                        <Row className='align-items-center justify-content-center'>
                            <Col md={4} className='d-flex justify-content-center align-items-center'><Card.Img variant="top" className="img-heading" src={selectedFeature.imageSrc} /></Col>
                            <Col md={8}><Card.Title className='fw-bold'>{selectedFeature.name}</Card.Title></Col>
                        </Row>
                        <Card.Body>

                            <Card.Text>{selectedFeature.description}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Features;
