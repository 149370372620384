import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import Main from './components/Main';
import BlogList from './components/BlogList';
import BlogDetails from './components/BlogDetails'
import KeyBenefits from './components/KeyBenefits';
import Features from './components/Features';
import EcoSystem from './components/EcoSystem';
import Footer from './components/Footer';
import Solutions from './components/Solutions';
import Products from './components/Products';
import Team from './components/Team';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="overlay"></div>
        <div className="content container">
        <ScrollToTop/>
          <Header />
          <Routes>
            <Route path="/" element={
              <>
                <Main />
                <KeyBenefits />
                <Features />
                <EcoSystem />
              </>
            } />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/products" element={<Products />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
