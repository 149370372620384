import React from 'react';
import PropTypes from 'prop-types';
import './ImageKey.css'; // Custom CSS if needed
import { Card } from 'react-bootstrap'; // Import Bootstrap components

const ImageKey = ({ imageSrc, textContent, textContent1 }) => {
  return (
    <div className="image-key">
      <Card className="text-center">
        <div className="card-inner">
          <div className="card-front">
            <img src={imageSrc} alt="Sample" className="img-fluid rounded" />
            <p className="image-name">{textContent}</p>
          </div>
          <div className="card-back">
            <p className="back-text"> 
              <ul>
                {textContent1.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              </p>
        </div>
        </div>
      </Card>
    </div>
  );
};

ImageKey.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  textContent1: PropTypes.string.isRequired,
};

export default ImageKey;