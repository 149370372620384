import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./Header.css"; // Import the CSS file

function Header() {
  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => {
    if (window.innerWidth < 992) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992 && expanded) {
        setExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [expanded]);

  const handleMenuItemClick = () => {
    if (window.innerWidth < 992) {
      setExpanded(false);
    }
  };

  return (
    <header className="header">
      <div className="container">
        <Navbar expand="lg" expanded={expanded} variant="dark">
          <Navbar.Brand as={RouterLink} to="/" className="logo1">
            <img
              className="logo1"
              src="logoMainWhite.png"
              alt="Logo"
              height="70rem"
            />
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse>
            
            <Nav className="ms-auto" onClick={closeMenu}>
              <Nav.Link
                as={RouterLink}
                to="/"
                className="nav-link"
                onClick={handleMenuItemClick}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={RouterLink}
                to="/solutions"
                className="nav-link"
                smooth={"true"}
                duration={500}
                offset={-70}
                onClick={handleMenuItemClick}
              >
                Solutions
              </Nav.Link>
              <Nav.Link
                as={RouterLink}
                to="/products"
                className="nav-link"
                smooth={"true"}
                duration={500}
                offset={-70}
                onClick={handleMenuItemClick}
              >
                Products
              </Nav.Link>
              <Nav.Link
                as={RouterLink}
                to="/blogs"
                className="nav-link"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleMenuItemClick}
              >
                Blogs
              </Nav.Link>
              <Nav.Link
                as={RouterLink}
                to="/team"
                className="nav-link"
                onClick={handleMenuItemClick}
              >
                Team
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
}

export default Header;
