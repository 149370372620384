// BlogDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { blogs } from './BlogData';
import './BlogDetails.css'
const BlogDetails = () => {
    const { id } = useParams();
    const blog = blogs.find(blog => blog.id === parseInt(id));

    return (
        <Container className="py-5 blog-container">
            <h1 className="text-center mb-5 fw-bold">{blog.title}</h1>
            <img src={blog.imageSrc} alt={blog.title} className="img-fluid mb-4" />
            <div>{blog.content}</div>
        </Container>
    );
};

export default BlogDetails;
