import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageKey from './ImageKey';
import './KeyBenefits.css'; // Custom CSS for KeyBenefits component

const KeyBenefits = () => {
    return (
        <Container className="py-5">
            <h1 className="keybenefits text-center fw-bold">Key Benefits</h1>
            <Row className="gx-4 gy-5">
                <Col lg={4} md={6}>
                    <div className="benefit-item">
                        <ImageKey
                            imageSrc='./RedDT.png'
                            textContent='Predictive Maintenance'
                            textContent1=
                            {["Unexpected machinery breakdowns cause delays and financial losses, while traditional maintenance reacts only after issues arise.",
                            "Klvin.ai's predictive maintenance uses IoT sensors to monitor equipment health and address problems before they cause failures reducing downtime and keeping production on track.",]}
                        />
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className="benefit-item">
                        <ImageKey
                            imageSrc='./opt.jpg'
                            textContent='Optimized Performance'
                            textContent1={["Efficiency is crucial in any industrial setting, requiring real-time insights into machinery performance to identify improvements.",
                            "Our AI-driven analytics provide actionable insights into equipment patterns, allowing you to optimize processes, reduce waste, and enhance productivity.",
                            ]}
                        />
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className="benefit-item">
                        <ImageKey
                            imageSrc='./enhan.png'
                            textContent='Enhanced Safety'
                            textContent1={["Safety is important in industrial environments, where equipment failures can cause accidents and injuries.",
                            "Klvin.ai's predictive maintenance system enhances efficiency and prioritizes safety by predicting potential hazards beforehand letting you take preventive measures to protect your employees."]}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default KeyBenefits;
