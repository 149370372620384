import React from 'react';
import './EcoSystem.css';
import { Container } from 'react-bootstrap';

const logos = [
    { src: './icreate.png', alt: 'iCreate', url: 'https://www.icreate.org.in/',height:'150px' },
    { src: './isb.png', alt: 'ISB', url: 'https://isbdlabs.org/',height:'100px' },
    { src: './T-WORKS.png', alt: 'T-WORKS', url: 'https://tworks.telangana.gov.in/' ,height:'150px'},
    { src: './meity.png', alt: 'Meity', url: 'https://www.meity.gov.in/' ,height:'200px'},
    { src: './itic.png', alt: 'ITIC', url: 'https://itic.iith.ac.in/',height:'200px' },
    { src: './MathWorks.png', alt: 'MathWorks', url: 'https://www.mathworks.com',height:'150px' },
    { src: './startUpIndia.png', alt: 'Startup India', url: 'https://www.startupindia.gov.in/',height:'150px' },
];

const EcoSystem = () => {
    return (
        <section className='py-5 eco1'>
            <h1 className=' text-center fw-bold mb-5'>Eco-System Partners</h1>
            <Container className='ecoSystem'>
                <div className='text-center mb-5'>

                    <p className='subheading'>
                        Building and maintaining strong business relationships is essential for long-term success and growth.
                    </p>
                    <p className='subheading'>
                        At KLVIN, we value the importance of collaborative partnerships founded on trust, transparency, and mutual respect.
                    </p>
                    <p className='subheading'>
                        We believe that effective communication, understanding our partners' needs, and delivering consistent value are the cornerstones of our relationships.
                    </p>
                </div>
                <div className='partner-logos-container'>
                    {logos.map((logo, index) => (
                        <div className='partner-logo'>
                        <a href={logo.url} target="_blank" rel="noopener noreferrer">
                            <img key={index} src={logo.src} alt={logo.alt} height={logo.height} />
                        </a>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    );
};

export default EcoSystem;
