import React, { useState } from 'react';
import './Solutions.css';

const sections = [
    {
        id: 'monitoring',
        title: 'Monitoring',
        subSections: [
            {
                id: 'monitoring-intro',
                title: 'Introduction to Monitoring',
                content: (
                    <p className="fs-5">
                        In any industrial setup, equipment and machinery are the backbone of productivity and efficiency. However, these machines are subject to wear and tear, leading to unexpected failures. Monitoring is the first line of defense in maintaining the health of industrial equipment. By continuously observing the operational parameters, we can identify anomalies early, ensuring that minor issues do not escalate into major problems.
                    </p>
                ),
            },
            {
                id: 'monitoring-problem',
                title: 'Problem',
                content: (
                    <p className="fs-5">
                        One of the significant challenges industries face is the lack of real-time visibility into the operational status of their machinery. Traditional maintenance practices rely heavily on scheduled inspections and manual checks, which can miss subtle signs of deterioration. Studies show that approximately 82% of mechanical failures occur because of gradual degradation that could have been detected through continuous monitoring. This lack of continuous monitoring can lead to unexpected downtime, resulting in substantial financial losses and operational disruptions. For instance, unplanned downtime can cost industrial manufacturers up to $260,000 per hour.
                    </p>
                ),
            },
            {
                id: 'monitoring-effects',
                title: 'Effects',
                content: (
                    <ul className="fs-5">
                        <li>
                            <p>Unplanned Downtime: Unplanned downtime accounts for up to 5% of total productive capacity in many industries, leading to lost revenue. For example, a single hour of downtime in an automotive manufacturing plant can result in a loss of $1.3 million.</p>
                        </li>
                        <li>
                            <p>High Repair Costs: Without proper monitoring, minor issues can go unnoticed and worsen over time, potentially causing catastrophic failures that require expensive repairs or replacements. This can increase maintenance costs by up to 30%.</p>
                        </li>
                        <li>
                            <p>Safety Risks: Our advanced monitoring solution leverages cutting-edge IoT technology and AI algorithms to provide continuous, real-time visibility into the health of your machinery. Sensors placed on critical components collect data on various parameters such as temperature, vibration, and pressure. This data is then transmitted to our cloud-based platform, where AI algorithms analyze it to detect anomalies and predict potential failures.</p>
                        </li>
                    </ul>
                ),
            },
            {
                id: 'monitoring-solution',
                title: 'Solution',
                content: (
                    <p className="fs-5">
                        Our advanced monitoring solution leverages cutting-edge IoT technology and AI algorithms to provide continuous, real-time visibility into the health of your machinery. Sensors placed on critical components collect data on various parameters such as temperature, vibration, and pressure. This data is then transmitted to our cloud-based platform, where AI algorithms analyze it to detect anomalies and predict potential failures.
                    </p>
                ),
            },
            {
                id: 'monitoring-advantages',
                title: 'Advantages',
                content: (
                    <ul className="fs-5">
                        <li>
                            <p>Proactive Maintenance: Our monitoring solution enables proactive maintenance by identifying issues before they lead to failures. This approach can reduce unplanned downtime by up to 50% and extend the lifespan of your equipment by 20-30%.</p>
                        </li>
                        <li>
                            <p>Cost Savings: By preventing major failures and optimizing maintenance schedules, our solution helps you save on repair costs and reduces the need for spare parts inventory, potentially saving up to 25% in maintenance costs.</p>
                        </li>
                        <li>
                            <p>Enhanced Safety: Continuous monitoring ensures that any deviations from normal operating conditions are quickly identified, mitigating the risk of accidents and ensuring a safer working environment.</p>
                        </li>
                        <li>
                            <p>Data-Driven Insights: Our platform provides comprehensive reports and dashboards, giving you valuable insights into the performance and health of your machinery. This data can be used to make informed decisions and optimize your operations, improving overall efficiency by up to 10%.</p>
                        </li>
                    </ul>
                ),
            },
        ],
    },
    {
        id: 'preventing',
        title: 'Preventing',
        subSections: [
            {
                id: 'preventing-intro',
                title: 'Introduction to Preventing',
                content: (
                    <p className="fs-5">
                        Prevention is always better than cure, especially when it comes to industrial equipment. Preventive maintenance is a strategy designed to keep machinery in optimal condition, thereby avoiding the occurrence of unexpected failures. By implementing preventive measures, industries can ensure smooth operations, reduce downtime, and enhance overall productivity.
                    </p>
                ),
            },
            {
                id: 'preventing-problem',
                title: 'Problem',
                content: (
                    <p className="fs-5">
                        In the absence of preventive maintenance, industries often rely on reactive maintenance, which only addresses issues after they have caused a failure. This approach is not only inefficient but also costly. Equipment that is not regularly maintained is more likely to experience breakdowns, which can lead to prolonged downtime and high repair costs. For instance, reactive maintenance is, on average, three times more expensive than preventive maintenance. Furthermore, without preventive measures, the likelihood of sudden and catastrophic failures increases by 60%, posing significant risks to both production and safety.
                    </p>
                ),
            },
            {
                id: 'preventing-effects',
                title: 'Effects',
                content: (
                    <ul className="fs-5">
                        <li>
                            <p>Unplanned Downtime: Unplanned downtime disrupts the production schedule, leading to delays and increased operational costs. For example, each hour of downtime in the oil and gas industry can cost up to $1 million.</p>
                        </li>
                        <li>
                            <p>Higher Maintenance Expenses: Frequent breakdowns result in higher maintenance expenses as emergency repairs are typically more costly than scheduled maintenance. Reactive maintenance can cost up to 50% more than preventive maintenance.</p>
                        </li>
                        <li>
                            <p>Increased Risk of Catastrophic Failures: The risk of catastrophic failures rises, which can cause extensive damage to equipment and even pose hazards to workers. Studies indicate that 70% of equipment failures could be prevented with a proper preventive maintenance program.</p>
                        </li>
                    </ul>
                ),
            },
            {
                id: 'preventing-solution',
                title: 'Solution',
                content: (
                    <p className="fs-5">
                        Our preventive maintenance solution is designed to keep your machinery in peak condition through regular inspections, timely interventions, and predictive analytics. By leveraging advanced technologies, we provide a comprehensive maintenance plan tailored to the specific needs of your equipment.
                    </p>
                ),
            },
            {
                id: 'preventing-advantages',
                title: 'Advantages',
                content: (
                    <ul className="fs-5">
                        <li>
                            <p>Reduced Downtime: Regular maintenance ensures that machinery operates smoothly, significantly reducing the likelihood of unexpected breakdowns and associated downtime. This can improve equipment availability by up to 40%.</p>
                        </li>
                        <li>
                            <p>Cost Efficiency: Preventive maintenance is more cost-effective than reactive maintenance. By addressing minor issues early, we prevent them from escalating into major problems that require expensive repairs, potentially reducing maintenance costs by 25-30%.</p>
                        </li>
                        <li>
                            <p>Extended Equipment Lifespan: Consistent maintenance extends the life of your equipment, ensuring that you get the most out of your investment. Equipment lifespan can be extended by up to 20% with regular preventive maintenance.</p>
                        </li>
                        <li>
                            <p>Improved Safety: Preventive measures reduce the risk of equipment malfunctions that could lead to accidents, ensuring a safer working environment for your employees. Safety incidents can be reduced by up to 35% with an effective preventive maintenance program.</p>
                        </li>
                        <li>
                            <p>Enhanced Reliability: With machinery running reliably, you can meet production targets consistently, maintaining customer satisfaction and enhancing your organization’s reputation.</p>
                        </li>
                    </ul>
                ),
            },
        ],
    },
    {
        id: 'predicting',
        title: 'Predicting',
        subSections: [
            {
                id: 'predicting-intro',
                title: 'Introduction to Predicting',
                content: (
                    <p className="fs-5">
                        Predictive maintenance is the pinnacle of modern maintenance strategies. By utilizing advanced analytics and machine learning algorithms, predictive maintenance foresees potential equipment failures before they occur. This forward-looking approach allows industries to take proactive steps to prevent downtime and optimize maintenance schedules.
                    </p>
                ),
            },
            {
                id: 'predicting-problem',
                title: 'Problem',
                content: (
                    <p className="fs-5">
                        Traditional maintenance practices, such as reactive and preventive maintenance, have limitations when it comes to detecting early signs of equipment deterioration. Reactive maintenance addresses issues after they occur, leading to costly downtime and repairs. On the other hand, preventive maintenance relies on scheduled inspections, which may not always align with real-time data and operating conditions. As a result, industries may experience unexpected downtime, increased maintenance costs, and diminished operational efficiency.
                    </p>
                ),
            },
            {
                id: 'predicting-effects',
                title: 'Effects',
                content: (
                    <ul className="fs-5">
                        <li>
                            <p>Unplanned Downtime: Unplanned downtime disrupts operations and affects production schedules, leading to lost revenue and increased operational costs. For instance, downtime in the manufacturing sector can cost up to $260,000 per hour.</p>
                        </li>
                        <li>
                            <p>High Maintenance Costs: Traditional maintenance strategies may result in unnecessary maintenance activities, increasing overall maintenance costs. Predictive maintenance can reduce maintenance costs by up to 30% by optimizing maintenance schedules and preventing unnecessary interventions.</p>
                        </li>
                        <li>
                            <p>Reduced Equipment Reliability: Without accurate insights into equipment health, industries may experience reduced equipment reliability, leading to decreased operational efficiency and customer dissatisfaction.</p>
                        </li>
                    </ul>
                ),
            },
            {
                id: 'predicting-solution',
                title: 'Solution',
                content: (
                    <p className="fs-5">
                        Our predictive maintenance solution leverages advanced analytics and machine learning algorithms to provide real-time insights into equipment health. By analyzing data from sensors and historical maintenance records, we predict potential failures before they occur, allowing you to take proactive measures and optimize maintenance schedules.
                    </p>
                ),
            },
            {
                id: 'predicting-advantages',
                title: 'Advantages',
                content: (
                    <ul className="fs-5">
                        <li>
                            <p>Improved Equipment Reliability: Predictive maintenance ensures that equipment operates reliably, reducing the risk of unexpected failures and associated downtime. This improves overall equipment effectiveness (OEE) by up to 20%.</p>
                        </li>
                        <li>
                            <p>Cost Savings: By predicting failures and optimizing maintenance schedules, our solution helps you save on maintenance costs and reduce the need for spare parts inventory. Maintenance costs can be reduced by up to 30% with predictive maintenance.</p>
                        </li>
                        <li>
                            <p>Increased Productivity: With machinery operating reliably, you can meet production targets consistently, improving overall operational efficiency and customer satisfaction.</p>
                        </li>
                        <li>
                            <p>Data-Driven Decision Making: Our platform provides actionable insights into equipment health and performance, empowering you to make informed decisions and optimize your operations.</p>
                        </li>
                    </ul>
                ),
            },
        ],
    },
];

const Solutions = () => {
    const [selectedMainButton, setSelectedMainButton] = useState(sections[0].id);
    const [selectedSubSection, setSelectedSubSection] = useState(sections[0].subSections[0].id);

    const handleMainButtonClick = (id) => {
        setSelectedMainButton(id);
        setSelectedSubSection(sections.find(section => section.id === id).subSections[0].id);
    };

    const handleSubSectionClick = (id) => {
        setSelectedSubSection(id);
    };

    return (
        <div className="solutions-container">
            <div className="main-buttons">
                {sections.map(section => (
                    <button
                        key={section.id}
                        className={`main-button ${selectedMainButton === section.id ? 'active' : ''}`}
                        onClick={() => handleMainButtonClick(section.id)}
                    >
                        {section.title}
                    </button>
                ))}
            </div>
            <div className="content-container">
                <div className="sub-sections">
                    {sections.find(section => section.id === selectedMainButton).subSections.map(subSection => (
                        <button
                            key={subSection.id}
                            className={`sub-section ${selectedSubSection === subSection.id ? 'active' : ''}`}
                            onClick={() => handleSubSectionClick(subSection.id)}
                        >
                            {subSection.title}
                        </button>
                    ))}
                </div>
                <div className="content">
                    {sections.find(section => section.id === selectedMainButton).subSections
                        .find(subSection => subSection.id === selectedSubSection).content}
                </div>
            </div>
        </div>
    );
};

export default Solutions;
