import React from 'react';
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'


const Footer = () => {
    return (
        <footer className="bg-light py-5 footer">
            <Container>
                <Row className="gx-5">
                    <Col md={4} sm={6} className="mb-4">
                        <div className="footer-section">
                            <h3>About us</h3>
                            <p>
                                Klvin Technology Labs is a leading provider of innovative technology solutions. We specialize in IoT, AI, Blockchain, and Cloud Services, delivering exceptional results for our clients worldwide.
                            </p>
                        </div>
                    </Col>
                    <Col md={4} sm={6} className="mb-4">
                        <div className="footer-section">
                            <h3>Contact Us</h3>
                            <p>
                                <strong>Address:</strong> ISB Gachibowli<br />
                                <strong>Email:</strong> <a href="mailto:contact@klvin.ai">contact@klvin.ai</a>
                            </p>
                        </div>
                    </Col>
                    <Col md={4} sm={6} className="mb-4">
                        <div className="footer-section">
                            <h3>Follow Us</h3>
                            <div className="social-media"> 
                                <a href="https://linkedin.com/company/klvin-ai" target="_blank" rel="noopener noreferrer"> 
                                <FontAwesomeIcon icon={faLinkedin} size="xl" style={{color: "#74C0FC",}} />
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="footer-bottom text-center mt-4">
                            <p>&copy; {new Date().getFullYear()} Klvin Technology Labs. All rights reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
