// BlogList.js
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { blogs } from './BlogData';
import './BlogList.css'; // Create a CSS file for custom styles

const BlogList = () => {
    return (
        <Container className="py-5">
            <h1 className="text-center mb-5 fw-bold">Blogs</h1>
            <Row className='justify-content-center'>
                {blogs.map(blog => (
                    <Col md={4} key={blog.id} className="mb-4">
                        <Card>
                            <div className='image-container'>
                                <Card.Img variant="top" src={blog.imageSrc} alt={blog.imageAlt} />
                            </div>
                            <Card.Subtitle className='text-center --bs-info'>{blog.domain}</Card.Subtitle>
                            <Card.Body>
                                <Card.Title>{blog.title}</Card.Title>
                                <Card.Text>{blog.description}</Card.Text>
                                <Button as={Link} to={`/blog/${blog.id}`} variant="primary">Read More</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default BlogList;
