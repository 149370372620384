import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Team.css"; // Ensure you have the CSS file for custom styles



const Team = () => {
  const openLinkedIn = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <section className="team-section">
      <Container>
        <h1 className="text-center mb-5 fw-bold">The Team</h1>

        <Row className="justify-content-center">
          <Col>
            <Card className="team-card" onClick={() => openLinkedIn("https://linkedin.com/in/vinaykolusu")}>
              <Card.Img
                className="team-card-image"
                variant="top"
                src="./4.png"
                alt="Vinay Kolusu"
              />
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={6} sm={12} className="n-container">
            <Card className="team-card n-card" onClick={() => openLinkedIn("https://linkedin.com/in/likhith_gadde")}>
              <Card.Img
                className="team-card-image"
                variant="top"
                src="./5.png"
                alt="likhith gadde"
              />    
            </Card>
          </Col>

          <Col md={6} sm={12} className="n-container">
            <Card className="team-card n-card" onClick={() => openLinkedIn("https://linkedin.com/in/koushikyadav")}>
              <Card.Img
                className="team-card-image"
                variant="top"
                src="./6.png"
                alt="koushik yadav"
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Team;