import React, { useState } from "react";
import "./Solutions.css";
const sections = [
 {
    id: "machine",
    title: "Machine Report",
    subtitle:"Comprehensive Machine Monitoring Device",
    subSections: [
      {
        id: "machine-overview",
        title: "Overview",
        content: (
          <p className="fs-5">
                     Our Comprehensive Machine Monitoring Device is an all-in-one solution integrating multiple sensors to provide holistic monitoring of your industrial equipment. This state-of-the-art device combines temperature, vibration, pressure, and noise level sensors to deliver real-time, precise data on the health and performance of your machinery.

          </p>
        ),
      },
      {
        id: "machine-features",
        title: "Features",
        content: (
           <ul className=" text-black fs-5">
          <li>Multi-Sensor Integration: Seamlessly combines various sensors into a single device for comprehensive monitoring.</li>
          <li>Real-Time Data: Continuously monitors and transmits data in real-time, ensuring immediate detection of anomalies.</li>
          <li>High Precision: Utilizes advanced sensor technology to provide accurate and reliable measurements.</li>
          <li>Durability: Designed to withstand harsh industrial environments, ensuring long-term reliability.</li>
        </ul>

        ),
      },
      {
        id: "machine-benefits",
        title: "Benefits",
        content: (
          <ul className=" text-black fs-5">
          <li>Enhanced Operational Efficiency: By continuously monitoring critical parameters, our device helps maintain optimal machine performance, reducing downtime and increasing productivity.</li>
          <li>Cost Savings: Early detection of potential issues prevents major failures, saving on repair costs and minimizing unplanned downtime.</li>
          <li>Improved Safety: Ensures machines operate within safe parameters, reducing the risk of accidents and enhancing workplace safety.</li>
          <li>Ease of Integration: Compatible with existing systems, our device can be easily incorporated into your current setup without significant changes.</li>
        </ul>
        ),
      },
      {
        id: "machine-applications",
        title: "Applications",
        content: (
          <p className="fs-5">
               Ideal for use in a variety of industries, including manufacturing, oil and gas, automotive, and more, our Comprehensive Machine Monitoring Device is perfect for any setting that demands reliable and continuous equipment monitoring.
          </p>
        ),
      },
    ],
  }, 
  {
    id: "cloud",
    title: "Analytical Report",
    subtitle:"Cloud-Based Monitoring Platform",
    
    subSections: [
      {
        id: "cloud-overview",
        title: "Overview",
        content: (
          <p className="fs-5">
                  Our Cloud-Based Monitoring Platform is the heart of our monitoring solutions, providing a powerful interface for real-time data analysis and visualization. Leveraging cutting-edge AI algorithms, this platform offers unparalleled insights into the health and performance of your machinery.

          </p>
        ),
      },
      {
        id: "cloud-features",
        title: "Features",
        content: (
          <ul className=" text-black fs-5" >
          <li>Real-Time Data Analytics: Processes and analyzes data in real-time, providing instant insights and alerts.</li>
          <li>AI-Powered Predictive Analytics: Utilizes advanced machine learning algorithms to predict potential failures and optimize maintenance schedules.</li>
          <li>Customizable Dashboards: Create and customize dashboards to display the most relevant information for your operations.</li>
          <li>Scalability: Easily scalable to accommodate the monitoring needs of large industrial setups.</li>
          <li>Secure Data Storage: Ensures data integrity and security with robust cloud storage solutions.</li>
        </ul>
        ),
      },
      {
        id: "cloud-benefits",
        title: "Benefits",
        content: (
          <ul className=" text-black fs-5">
          <li>Proactive Maintenance: Predictive analytics enable proactive maintenance, reducing downtime and extending the lifespan of your equipment.</li>
          <li>Data-Driven Decision Making: Provides actionable insights that help you make informed decisions to optimize operations and reduce costs.</li>
          <li>Increased Visibility: Offers comprehensive visibility into the performance of your machinery, allowing for better management and control.</li>
          <li>Remote Monitoring: Monitor your equipment from anywhere, at any time, through our user-friendly interface.</li>
        </ul>
        ),
      },
      {
        id: "cloud-applications",
        title: "Applications",
        content: (
          <p className="fs-5">
                      Our Cloud-Based Monitoring Platform is designed for industries that require continuous, reliable monitoring and advanced data analytics, such as manufacturing, energy, and transportation sectors.

          </p>
        ),
      },
    ],
  },
  {
    id: "pred",
    title: "Predictive Report",
    subtitle:"Predictive Reports on Individual Assets and Collective Machines",
    subSections: [
      {
        id: "pred-overview",
        title: "Overview",
        content: (
          <p className="fs-5">
                   Our Predictive Reporting solution provides detailed, actionable insights into the performance and health of individual assets and collective machines on the factory floor. These reports harness the power of AI to forecast potential issues and recommend optimal maintenance strategies.

          </p>
        ),
      },
      {
        id: "pred-features",
        title: "Features",
        content: (
          <ul className="text-black fs-5">
          <li><p>Detailed Asset Reports: Provides in-depth analysis and performance metrics for individual machines.</p></li>
          <li><p>Comprehensive Factory Reports: Aggregates data from multiple machines to deliver a holistic view of factory operations.</p></li>
          <li><p>AI-Driven Predictions: Uses machine learning models to predict equipment failures and maintenance needs.</p></li>
          <li><p>Customizable Reports: Tailor reports to focus on specific parameters or machinery based on your operational requirements.</p></li>
          <li><p>Automated Reporting: Set up automated reporting schedules to receive regular updates without manual intervention.</p></li>
        </ul>
        ),
      },
      {
        id: "pred-benefits",
        title: "Benefits",
        content: (
          <ul className="text-black fs-5">
          <li><p>Optimized Maintenance: Predictive insights help schedule maintenance activities at the most opportune times, reducing downtime and extending equipment life.</p></li>
          <li><p>Enhanced Productivity: By anticipating and addressing issues before they become critical, our reports help maintain smooth and efficient operations.</p></li>
          <li><p>Informed Decision Making: Access to detailed and accurate reports enables better strategic planning and resource allocation.</p></li>
          <li><p>Reduced Operational Costs: Minimizes unplanned repairs and associated costs through proactive maintenance strategies.</p></li>
        </ul>
        ),
      },
      {
        id: "pred-applications",
        title: "Applications",
        content: (
          <p className=" text-black">
          Our Predictive Reports are essential for industries that rely heavily on machinery and equipment, such as manufacturing, mining, and logistics. These reports ensure that your operations run smoothly and efficiently by providing the insights needed to maintain and optimize your equipment.
        </p>
        ),
      },
    ],
  },
  
];

const Products = () => {
  const [selectedMainButton, setSelectedMainButton] = useState(sections[0].id);
  const [selectedSubSection, setSelectedSubSection] = useState(
    sections[0].subSections[0].id
  );

  const handleMainButtonClick = (id) => {
    setSelectedMainButton(id);
    setSelectedSubSection(
      sections.find((section) => section.id === id).subSections[0].id
    );
  };

  const handleSubSectionClick = (id) => {
    setSelectedSubSection(id);
  };

  return (
    <div className="products-container">
      <div className="main-buttons">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`main-button ${
              selectedMainButton === section.id ? "active" : ""
            }`}
            onClick={() => handleMainButtonClick(section.id)}
          >
            {section.title}
          </button>
        ))}
      </div>
      <div className="content-container">
        <div className="sub-sections">
          {sections
            .find((section) => section.id === selectedMainButton)
            .subSections.map((subSection) => (
              <button
                key={subSection.id}
                className={`sub-section ${
                  selectedSubSection === subSection.id ? "active" : ""
                }`}
                onClick={() => handleSubSectionClick(subSection.id)}
              >
                {subSection.title}
              </button>
            ))}
        </div>
        <div className="content">
          <div className="subtitle fs-4 fw-bold mx-auto text-center">{sections
              .find((section) => section.id === selectedMainButton).subtitle}</div>
          {
            sections
              .find((section) => section.id === selectedMainButton)
              .subSections.find(
                (subSection) => subSection.id === selectedSubSection
              ).content
          }
        </div>
      </div>
    </div>
  );
};

export default Products;
